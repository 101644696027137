<template>
  <div>     
    <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">申请借支</div>
    </div>
  </nav-bar-back>
        <div class="myheader">
            <p class="des">借支金额</p>
           <div class="money">
            <button v-for="(item,index) in bts" :key="item" :class="index==flag?'selected':'unSelected'" @click="clickMoney(index)"> {{item}}元</button>
           </div>

        </div>
 <div class="myinfoContent">  
  <p class="des">个人信息</p>
<div class="info"> 
    <van-cell class="cell" >
        <div class="item">
  <p>姓名</p>
  <p>{{name}}</p>
        </div>
    </van-cell>

    <van-cell  class="cell"  :to="{ path: '/Audit', query: { id: 1 }}" >
        <div class="item">
  <p>身份证号码</p>
  <p>{{idCard}}</p>
        </div>
    </van-cell>

    <van-cell class="cell" >
        <div class="item">
  <p>在职部门</p>
  <p>{{depName}}</p>
        </div>
    </van-cell>
    <van-cell class="cell" >
        <div class="item">
  <p>工号</p>
  <p>{{workID}}</p>
        </div>
    </van-cell>
</div>

 <div class="myheader">
            <p class="des">收款信息</p>
           <div class="money">
            <button v-for="(item,index) in moneyWay" :key="item" :class="index==way?'selected':'unSelected'" @click="clickWay(index)" style="width:43%"> {{item}}</button>
           </div>
         
         <div class="fields">

        
  <van-field
  v-if="way==0"
    v-model="wxMessage"
    rows="1"
    autosize
    label="微信号:"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="请填写本人微信账号"
  />
  <div v-else>
        
  <van-field
    
    v-model="bankName"
    rows="1"
    autosize
    label="银行名称:"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="请输入银行名称"
  />        
  <van-field

    v-model="bankCode"
    rows="1"
    autosize
    label="银行卡号:"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="请填写本人银行卡号"
  />
  </div>
  
 </div>
        </div>
    <van-button  class="apply" @click="apply">立即申请</van-button>

</div>

  
<!--  -->
  </div>
</template>

<script>
import { Cell, CellGroup,Field,Button, Toast } from 'vant';
import {borrow2,borrow1,user} from "network/apply";

import NavBarBack from 'common/navbar/NavBarBack'
export default {
  components: {
      NavBarBack,
       [Cell.name]:Cell,
       [CellGroup.name]:CellGroup,
       [Field.name]:Field,
       [Button.name]:Button
  },
  methods: {
    //   applyResult
    apply(){

        if(this.way==0){
            //微信支付
            if(!this.wxMessage){
                Toast.fail("请填写微信号")
                return
            }
  
    //   console.log(this.bts[this.flag],this.flag+1,this.wxMessage)
        borrow1(this.bts[this.flag],this.flag+1,this.wxMessage).then(res=>{
            // console.log(res,'ff')
        
        this.$router.push({path:'/applyResult',query:res})
         Toast.success("申请成功")
        
        })
// money, type, bank_name, bank_cart
        }else{
            if(!this.bankName){
                Toast.fail('请填写银行名称')
                return 
            }
            if(!this.bankCode){
                Toast.fail('请填写银行卡号')
                return
            }
 borrow2(this.bts[this.flag],this.flag+1,this.bankName,this.bankCode).then(res=>{
            // console.log(res,'ff')
        
        this.$router.push({path:'/applyResult',query:res})
         Toast.success("申请成功")
        
        })

        }
    },
    clickMoney(index){
       this.flag=index
    },
      clickWay(index){
       this.way=index
    },
    
  },
  created () {
    user().then(res=>{
        //  console.log(res)
         this.name=res.name,
         this.idCard=res.id_card,
         this.depName=res.factory_name+res.department
         this.workID=res.work_number
    })  
  },
  data () {
      return {
          flag:0,
          way:0,
          name:'未知',
          idCard:'未知',
          status:'已绑定',
          depName:'未知',
          workID:'未知',
          bts:['100','200','300'],
          moneyWay:['微信','银行卡'],
          wxMessage:'',
          bankName:'',
          bankCode:'',
          type:''

      }
  }
}
</script>

<style scoped>
.apply{
     width: 93%;
    margin: 30px auto 20px auto;
    border-radius: 5px;
    color: #fff;
    /* background-color:  */
     background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71));
    /* background-color: #f00 */
}
.fields{
    background-color: #fff;
      width: 93%;
      border-radius: 4px;
      margin: 0 auto 0 auto;

}
.field{
    display: flex;
    align-items: center;
    /* width: 94%; */
    background-color: transparent;
    
}
.myheader{
    margin-top: 20px;
}
.money{
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 20px
}
.selected{
    font-size: 14px;
    border: none;
    color: hsl(0, 0%, 94%);
    width: 27%;
    height: 35px;
    border-radius: 5px;
       box-shadow: 0px 1px 1px #ccc;

    background-color: rgb(250, 115, 27)
}
.unSelected{
    border: none;
    color: #333;
    width: 27%;
    font-size: 14px;

    height: 35px;
    border-radius: 5px;
    box-shadow: 0px 1px 1px #ccc;
    background-color: #fff
}
.des{
    margin-left: 10px;
    font-size: 14px;
}
.myinfoContent{
   width: 100%;
   display: flex;
   flex-direction: column;
}

.info{
    width: 92%;
    padding: 1px;
    
     background-color: white;
     border-radius: 5px;
    margin: 20px auto 0 auto 
}
.item{
    display: flex;
    justify-content: space-between
}
.cell{
    background-color: tranredsparent !important 
    /* margin-top: 10px; */
}
.item img{
    width: 23px;
    height: 23px;;
}
</style>





