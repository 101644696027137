// api/v1/borrow
//申请借支


import axios from './axios'


export function borrow1(money, type, weixin) {
    return axios({
        url: 'api/v1/borrow',
        method: 'POST',
        params: {
            money,
            type,
            weixin
        }
    })
}
export function borrow2(money, type, bank_name, bank_cart) {
    return axios({
        url: 'api/v1/borrow',
        method: 'POST',
        params: {
            money,
            type,
            bank_name,
            bank_cart
        }
    })
}



export function user() {
    return axios({
        url: 'api/v1/user',
    })
}
//详情
export function detail(id) {
    return axios({
        url: 'api/v1/borrow/' + id,

    })
}
//获取所有的列表的数据

export function getborrows() {
    return axios({
        url: 'api/v1/borrow/',
    })
}




// getDataWeb(){
//     console.log('link',this.link.next)

//   axios.get(this.link.next).then(res=>{
//       this.borrows.push(res.data)
//       this.link=res.links
//       console.log(this.borrows,this.link)
//       if(!this.link){
//       console.log('数据为空',this.link)
//       this.$refs.scroll.forceUpdate();
//       }
//   })
// },
export function getborrowsNext(next) {
    return axios({
        url: next,
    })
}

//取消借支 v1/borrow/cancel/:id

export function cancel(id) {
    return axios({
        url: 'api/v1/borrow/cancel/' + id,

    })
}
// api / v1 / borrow