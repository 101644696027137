<template>
  <div>
     <nav-bar class="detail-nav">
     <div class="back" slot="left" >
      
        <van-icon name="arrow-left" size='25px' @click="backClick"/>
    </div>    
    <div class="title" slot="center">
      <div slot="center"> <slot name="title">标题</slot></div>
     <!-- <div   v-if="type!=2&&type!=3" slot="center"> 预约成功</div> -->
    </div>
 </nav-bar>
  </div>
</template>

<script>
import NavBar from 'common/navbar/NavBar'
import { Icon } from 'vant';
export default {
      name:'NavBarBack',
      components: {
          NavBar,
          [Icon.name]:Icon
      },
      methods: {
          backClick(){
             this.$router.back()
          }
      }
}
</script>

<style>

.back{
 /* background-color: aquamarine; */
   align-content: center;
   height: 55px;
   padding-top: 5px;
   line-height: 55px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
</style>